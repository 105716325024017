
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { Mutual } from "@/core/model/mutual";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";
import MutualInfo from "@/views/mutuales/MutualInfo.vue";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "admin-mutual-payment-plans",
  components: {
    MutualInfo,
    Datatable,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);
    const rowsPerPage = ref(10);
    const currentPage = ref(1);
    const total = ref(0);
    const tableData = ref<Array<Mutual>>([]);
    let sortOrder = "asc";
    let sortField = "name";
    let currentSortColumn = ref("nameasc");
    const search = ref<string>("");

    const tableHeader = ref([
      {
        name: "Nombre",
        key: "name",
        sortable: true,
      },
      {
        name: "Descipción",
        key: "description",
        sortable: true,
      },
      {
        name: "Cuotas",
        key: "max_quotas",
        sortable: true,
        class: "text-end px-9",
      },
      {
        name: "TEA",
        key: "interest_rate",
        sortable: true,
        class: "text-end px-9",
      },
      {
        name: "Precio mínimo",
        key: "minimal_price",
        sortable: true,
        class: "text-end px-9",
      },
      {
        name: "Estado",
        key: "active",
        noEnd: true,
        //sortingField: "status.label",
        sortable: true,
        class: "text-end px-9",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Administrar Mutual", [
        "Mutuales",
        "Planes de pago",
      ]);
      loadData();
    });

    const loadData = () => {
      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
        filter_mutual: route.params.uuid,
      };
      loading.value = true;
      store.dispatch(Actions.ADMIN_PAYMENT_PLANS_LIST, params).then(() => {
        tableData.value.splice(
          0,
          tableData.value.length,
          ...store.state.PaymentPlansModule.list.items
        );
        loading.value = false;
        rowsPerPage.value = store.state.PaymentPlansModule.list.limit;
        currentPage.value = store.state.PaymentPlansModule.list.page;
        total.value = store.state.PaymentPlansModule.list.total;
      });
    };

    const paginationChangePage = function (page) {
      currentPage.value = page;
      loadData();
    };

    const paginationPerPageChange = function (limit) {
      rowsPerPage.value = limit;
      loadData();
    };

    const sortList = function (data) {
      sortField = data.columnName;
      sortOrder = data.order;
      currentSortColumn.value = sortField + sortOrder;
      loadData();
    };

    return {
      tableData,
      tableHeader,
      search,
      loading,
      rowsPerPage,
      currentPage,
      total,
      paginationChangePage,
      paginationPerPageChange,
      sortList,
      currentSortColumn,
    };
  },
});
